import AppRouter from "./router";
import "./assets/css/skin.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/home.css";
import "./assets/css/main.css";
import "react-bootstrap"

function App() {
  return <AppRouter />;
}

export default App;
