import {
  DELL_5420_1,
  DELL_5420_2,
  DELL_5430_1,
  DELL_5430_2,
  Dell_5440_1,
  Dell_5440_2,
  Dell_5450_1,
  Dell_5450_2,
  Dell_5470_1,
  DELL_5470_2,
  DELL_5520_1,
  DELL_5520_2,
  DELL_5520_Precision_1,
  DELL_5520_Precision_2,
  Dell_5559_1,
  DELL_6220_1,
  DELL_6220_2,
  // DELL_6230_1,
  // DELL_6230_2,
  DELL_6420_1,
  DELL_6420_2,
  DELL_6430_1,
  DELL_6430_2,
  DELL_6440_1,
  DELL_6440_2,
  Dell_7240_1,
  Dell_7240_2,
  Dell_7240_3,
  Dell_7250_1,
  Dell_7250_2,
  Dell_7250_3,
  DELL_7270_1,
  Dell_7270_2,
  DELL_7270_3,
  DELL_7450_1,
  DELL_7450_2,
  Dell_7470_1,
  DELL_7470_2,

  //
  HP_430G2_1,
  HP_430G2_2,
  HP_440G3_1,
  HP_440G3_2,
  // HP_450G1_1,
  // HP_450G1_2,
  HP_450G2_1,
  HP_450G2_2,
  HP_450G5_1,
  HP_450G5_2,
  HP_640G1_1,
  HP_640G2_1,
  HP_640G2_2,
  HP_650G1_1,
  HP_650G1_2,
  HP_820G1_1,
  HP_820G1_2,
  HP_820G2_1,
  HP_820G2_2,
  HP_840G1_1,
  HP_840G1_2,
  HP_840G1_3,
  HP_840G2_1,
  HP_840G2_2,
  HP_840G3_1,
  HP_840G3_2,
  HP_840G3_3,
  HP_Z15_G1_1,

  //
  Lenovo_240_1,
  Lenovo_240_2,
  Lenovo_240_3,
  Lenovo_250_1,
  Lenovo_250_2,
  Lenovo_320_1,
  Lenovo_330_1,
  Lenovo_330_2,
  Lenovo_440_1,
  Lenovo_440_2,
  Lenovo_440_3,
  Lenovo_450_1,
  Lenovo_450_2,
  Lenovo_450_3,
  Lenovo_460_1,
  Lenovo_460_2,
  Lenovo_460_3,

  //
  Apple_M12021_1,
  Apple_M12021_2,
  Apple_MID2012_1,
  Apple_MID2012_2,
  Apple_M22022_1,

  //
  kingfast_SSD,
  Dell_charger_normal_pin,
  hp_normal_pin_charger,
  Dell_xps_pin_charger,
  hp_blue_pin_charger,
  DDR3_DDR3L_RAM,
  DDR4_RAM,
  Harddrive_500GB,
  Harddrive_1TB,
  Lenovo_USB_Charger,
} from "../utils/images";

export const productsList = [
  {
    id: 1,
    name: "Dell-Latitude E5470 Ci5 6th",
    price: 52_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description:
      "DELL LATITUDE SERIES IS BEST LAPTOP FOR PROFESSIONAL USE, 14 INCH",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Dell_5470_1, DELL_5470_2],
    details: {
      Series: "Latitude",
      Model: "E5470",
      CPU: "i5",
      Generation: "6",
      Ram: "8",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 2,
    name: "Dell-Latitude E7450 Ci5 5th",
    price: 44_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "14 INCH",
    discount: 0,
    discountType: "percentage",
    images: [DELL_7450_1, DELL_7450_2],
    inStock: true,
    details: {
      Series: "Latitude",
      Model: "E7450",
      CPU: "i5",
      Generation: "5",
      Ram: "4",
      HDD: "256",
      Condition: "Used",
    },
  },
  {
    id: 3,
    name: "Dell-Latitude E6440 Ci5 4th",
    price: 35_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "14 INCH LAPTOP",
    discount: 0,
    discountType: "percentage",
    images: [DELL_6440_1, DELL_6440_2],
    inStock: true,
    details: {
      Series: "Latitude",
      Model: "E6440",
      CPU: "i5",
      Generation: "4",
      Ram: "4",
      HDD: "320",
      Condition: "Used",
    },
  },
  {
    id: 4,
    name: "Dell-Latitude E5520 Ci5 2nd",
    price: 26_500,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "15 INCH",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [DELL_5520_1, DELL_5520_2],
    details: {
      Series: "Latitude",
      Model: "E5520",
      CPU: "i5",
      Generation: "2",
      Ram: "4",
      HDD: "320",
      Condition: "Used",
    },
  },
  {
    id: 5,
    name: "Dell-Latitude E6420 Ci5 2nd",
    price: 24_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "14  INCH",
    discount: 0,
    discountType: "percentage",
    images: [DELL_6420_1, DELL_6420_2],
    inStock: true,
    details: {
      Series: "Latitude",
      Model: "E6420",
      CPU: "i5",
      Generation: "2",
      Ram: "4",
      HDD: "250",
      Condition: "Used",
    },
  },
  {
    id: 6,
    name: "Dell-Latitude E6220 Ci5 2nd",
    price: 22_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "12.5 INCH SIZE",
    discount: 0,
    discountType: "percentage",
    images: [DELL_6220_1, DELL_6220_2],
    inStock: true,
    details: {
      Series: "Latitude",
      Model: "E6220",
      CPU: "i5",
      Generation: "2",
      Ram: "4",
      HDD: "320",
      Condition: "Used",
    },
  },
  {
    id: 7,
    name: "Dell-Latitude E5440 Ci3 4th",
    price: 27_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "14 INCH SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Dell_5440_1, Dell_5440_2],
    details: {
      Series: "Latitude",
      Model: "E5440",
      CPU: "i3",
      Generation: "4",
      Ram: "4",
      HDD: "320",
      Condition: "Used",
    },
  },
  {
    id: 8,
    name: "Dell-Latitude E5430 Ci3 3rd",
    price: 22_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description: "14 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [DELL_5430_1, DELL_5430_2],
    details: {
      Series: "Latitude",
      Model: "E5430",
      CPU: "i3",
      Generation: "3",
      Ram: "4",
      HDD: "320",
      Condition: "Used",
    },
  },
  {
    id: 9,
    name: "Dell-Latitude E5440 Ci5 4rth",
    price: 35_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "14 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Dell_5440_1, Dell_5440_2],
    details: {
      Series: "Latitude",
      Model: "E5440",
      CPU: "i5",
      Generation: "4",
      Ram: "4",
      HDD: "320",
      Condition: "Used",
    },
  },
  {
    id: 10,
    name: "Dell-Latitude E5420 Ci5 2nd",
    price: 24_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description:
      "Core i5 2nd generation 14inch screen size professional laptop",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [DELL_5420_1, DELL_5420_2],
    details: {
      Series: "Latitude",
      Model: "E5420",
      CPU: "i5",
      Generation: "2nd",
      Ram: "4",
      HDD: "320",
      Condition: "Used",
    },
  },
  {
    id: 11,
    name: "Dell-Latitude E6430 Ci5 3rd",
    price: 27_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description:
      "Core i5 3rd generation 14inch screen size professional laptop",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [DELL_6430_1, DELL_6430_2],
    details: {
      Series: "Latitude",
      Model: "E6430",
      CPU: "i5",
      Generation: "3rd",
      Ram: "4",
      HDD: "320",
      Condition: "Used",
    },
  },
  {
    id: 12,
    name: "Dell-Latitude E5450 Ci5 5th",
    price: 42_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description:
      "Core i5 5th generation 14inch screen size professional laptop",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Dell_5450_1, Dell_5450_2],
    details: {
      Series: "Latitude",
      Model: "E5450",
      CPU: "i5",
      Generation: "5th",
      Ram: "8",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 13,
    name: "Lenovo-ThinkPad X250 Ci5 5th",
    price: 38_000,
    categoryId: 3,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "12.5 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Lenovo_250_1, Lenovo_250_2],
    details: {
      Series: "ThinkPad",
      Model: "X250",
      CPU: "i5",
      Generation: "5",
      Ram: "4",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 14,
    name: "Lenovo-ThinkPad T450 Ci5 5th",
    price: 40_000,
    categoryId: 3,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "14 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Lenovo_450_1, Lenovo_450_2, Lenovo_450_3],
    details: {
      Series: "ThinkPad",
      Model: "T450",
      CPU: "i5",
      Generation: "5",
      Ram: "8",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 15,
    name: "Lenovo-ThinkPad T440 Ci5 4th",
    price: 34_000,
    categoryId: 3,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description: "14 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Lenovo_440_1, Lenovo_440_2, Lenovo_440_3],
    details: {
      Series: "ThinkPad",
      Model: "T440",
      CPU: "i5",
      Generation: "4",
      Ram: "4",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 16,
    name: "Lenovo-ThinkPad T460 Ci5 6th",
    price: 50_000,
    categoryId: 3,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description: "14 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Lenovo_460_1, Lenovo_460_2, Lenovo_460_3],
    details: {
      Series: "ThinkPad",
      Model: "T460",
      CPU: "i5",
      Generation: "6",
      Ram: "8",
      HDD: "256",
      Condition: "Used",
    },
  },
  {
    id: 17,
    name: "HP-PROBOOK 430g2 Ci5 5th",
    price: 39_000,
    categoryId: 2,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "13 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [HP_430G2_1, HP_430G2_2],
    details: {
      Series: "PROBOOK",
      Model: "430g2",
      CPU: "i5",
      Generation: "5",
      Ram: "4",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 18,
    name: "HP-PROBOOK 450g2 Ci3 5th",
    price: 36_000,
    categoryId: 2,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "15 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [HP_450G2_1, HP_450G2_2],
    details: {
      Series: "PROBOOK",
      Model: "450g2",
      CPU: "i3",
      Generation: "5",
      Ram: "4",
      HDD: "320",
      Condition: "Used",
    },
  },
  {
    id: 19,
    name: "HP-Probook 650g1 Ci5 4th",
    price: 39_000,
    categoryId: 2,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "15 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [HP_650G1_1, HP_650G1_2],
    details: {
      Series: "Probook",
      Model: "650g1",
      CPU: "i5",
      Generation: "4",
      Ram: "4",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 20,
    name: "HP-Elitebook 820g2 Ci5 5th",
    price: 39_000,
    categoryId: 2,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description: "12.5 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [HP_820G2_1, HP_820G2_2],
    details: {
      Series: "Elitebook",
      Model: "820g2",
      CPU: "i5",
      Generation: "5",
      Ram: "8",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 21,
    name: "HP-Elitebook 820g1 Ci5 4th",
    price: 34_000,
    categoryId: 2,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "12.5 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [HP_820G1_1, HP_820G1_2],
    details: {
      Series: "Elitebook",
      Model: "820g1",
      CPU: "i5",
      Generation: "4",
      Ram: "4",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 22,
    name: "Dell-Latitude E7270 Ci5 6th",
    price: 43_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description:
      "Core i5 6th generation 12.5 inch screen size professional laptop",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [DELL_7270_1, Dell_7270_2, DELL_7270_3],
    details: {
      Series: "Latitude",
      Model: "E7270",
      CPU: "i5",
      Generation: "6th",
      Ram: "8",
      HDD: "128ssd",
      Condition: "Used",
    },
  },
  {
    id: 23,
    name: "Dell-Latitude E7250 Ci5 5th",
    price: 38_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description:
      "Core i5 5th generation 12.5 inch screen size professional laptop",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Dell_7250_1, Dell_7250_2, Dell_7250_3],
    details: {
      Series: "Latitude",
      Model: "E7250",
      CPU: "i5",
      Generation: "5th",
      Ram: "8",
      HDD: "128 SSD",
      Condition: "Used",
    },
  },
  {
    id: 24,
    name: "Dell-Latitude E7470 Ci5 6th",
    price: 54_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description:
      "Professional laptop you can work on it 24/7 without any worry",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Dell_7470_1, DELL_7470_2],
    details: {
      Series: "Latitude",
      Model: "E7470",
      CPU: "i5",
      Generation: "6",
      Ram: "8",
      HDD: "256 ssd",
      Condition: "Used",
    },
  },
  {
    id: 25,
    name: "HP-Elitebook 840g1 Ci5 4th",
    price: 38_000,
    categoryId: 2,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "14 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [HP_840G1_1, HP_840G1_2, HP_840G1_3],
    details: {
      Series: "Elitebook",
      Model: "840g1",
      CPU: "i5",
      Generation: "4",
      Ram: "4",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 26,
    name: "HP-Elitebook 840g2 Ci5 5th",
    price: 44_000,
    categoryId: 2,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "14 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [HP_840G2_1, HP_840G2_2],
    details: {
      Series: "Elitebook",
      Model: "840g2",
      CPU: "i5",
      Generation: "5th",
      Ram: "4",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 27,
    name: "HP-Elitebook 840g3 Ci5 6th",
    price: 57_000,
    categoryId: 2,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "14 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [HP_840G3_1, HP_840G3_2, HP_840G3_3],
    details: {
      Series: "Elitebook",
      Model: "840g3",
      CPU: "i5",
      Generation: "6th",
      Ram: "8",
      HDD: "256",
      Condition: "Used",
    },
  },
  {
    id: 28,
    name: "HP-PROBOOK 640G1 Ci5 4th",
    price: 35_000,
    categoryId: 2,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "14 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [HP_640G1_1],
    details: {
      Series: "PRObook",
      Model: "640G1",
      CPU: "i5",
      Generation: "4th",
      Ram: "4",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 29,
    name: "HP-PROBook 640g2 Ci5 6th",
    price: 52_000,
    categoryId: 2,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "14 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [HP_640G2_1, HP_640G2_2],
    details: {
      Series: "Probook",
      Model: "640g2",
      CPU: "i5",
      Generation: "6th",
      Ram: "8",
      HDD: "256",
      Condition: "Used",
    },
  },
  {
    id: 30,
    name: "HP-PROBook 440g3 Ci5 6th",
    price: 50_000,
    categoryId: 2,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "14 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [HP_440G3_1, HP_440G3_2],
    details: {
      Series: "Probook",
      Model: "440g3",
      CPU: "i5",
      Generation: "6th",
      Ram: "8",
      HDD: "256",
      Condition: "Used",
    },
  },
  {
    id: 31,
    name: "Dell Precision 5520 WORKSTATION Core i7 ,7th HQ",
    price: 145_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description: `Dell Precision 5520 Ci7-7820HQ Quadcore 16 Ram 512GB M.2 SSD Drive 4GB NVidia Quadro M1200 Graphic Card 15.6" 4k Touch Display`,
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [DELL_5520_Precision_1, DELL_5520_Precision_2],
    details: {
      Series: "Precision",
      Model: "5520",
      CPU: "i7",
      Generation: "7th",
      Ram: "16",
      HDD: "512 gb ssd",
      Condition: "Used",
    },
  },
  {
    id: 32,
    name: "HP-PROBook 450g5 Ci7 8th",
    price: 88_000,
    categoryId: 2,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description: "15 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [HP_450G5_1, HP_450G5_2],
    details: {
      Series: "Probook",
      Model: "450g5",
      CPU: "i7",
      Generation: "8th",
      Ram: "8",
      HDD: "256",
      Condition: "Used",
    },
  },
  {
    id: 33,
    name: "Lenovo-ThinkPad X240 Ci5 4th",
    price: 34_000,
    categoryId: 3,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "12.5 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Lenovo_240_1, Lenovo_240_2, Lenovo_240_3],
    details: {
      Series: "ThinkPad",
      Model: "X240",
      CPU: "i5",
      Generation: "4th",
      Ram: "4",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 34,
    name: "Lenovo-ideapad 330 Ci5 7th",
    price: 58_000,
    categoryId: 3,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description: "",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Lenovo_330_1, Lenovo_330_2],
    details: {
      Series: "ideaPad",
      Model: "330",
      CPU: "i5",
      Generation: "7",
      Ram: "8",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 35,
    name: "Lenovo-ideapad 320 Ci5 7th",
    price: 55_000,
    categoryId: 3,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "14 inch laptop for business use",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Lenovo_320_1],
    details: {
      Series: "ideaPad",
      Model: "320",
      CPU: "i5",
      Generation: "7",
      Ram: "8",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 36,
    name: "HP zbook 15 g1 core i7 4th gen",
    price: 65_000,
    categoryId: 2,
    type: "laptop",
    active: true,
    isNewArrival: false,
    description: "15 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [HP_Z15_G1_1],
    details: {
      Series: "zbook",
      Model: "g1",
      CPU: "i5",
      Generation: "7",
      Ram: "8",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 37,
    name: "Dell-inspiron 5559 Ci5 6th",
    price: 58_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description: "15 INCH SCREEN SIZE",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Dell_5559_1],
    details: {
      Series: "inspiron",
      Model: "5559",
      CPU: "i5",
      Generation: "6",
      Ram: "8",
      HDD: "500",
      Condition: "Used",
    },
  },
  {
    id: 38,
    name: "Macbook pro 2012 MID",
    price: 48_000,
    categoryId: 1,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description: "13 inch laptop for Professional use",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Apple_MID2012_1, Apple_MID2012_2],
    details: {
      Series: "PRO",
      Model: "2012 MID",
      CPU: "i5",
      Generation: "",
      Ram: "8",
      HDD: "128 SSD",
      Condition: "Used",
    },
  },
  {
    id: 39,
    name: "Macbook pro 2021",
    price: 248_000,
    categoryId: 1,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description: "13 inch laptop for Professional use",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Apple_M12021_1, Apple_M12021_2],
    details: {
      Series: "PRO",
      Model: "2021",
      CPU: "M1 CHIP",
      Generation: "",
      Ram: "8",
      HDD: "256 SSD",
      Condition: "NEW",
    },
  },
  {
    id: 40,
    name: "Macbook pro 2022",
    price: 455_000,
    categoryId: 1,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description: "16 inch laptop for Professional Use",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Apple_M22022_1],
    details: {
      Series: "PRO",
      Model: "2022",
      CPU: "M1 PRO CHIP",
      Generation: "",
      Ram: "16",
      HDD: "512GB SSD",
      Condition: "NEW",
    },
  },
  {
    id: 41,
    name: "Dell-Latitude E7240 Ci5 4th",
    price: 34_000,
    categoryId: 4,
    type: "laptop",
    active: true,
    isNewArrival: true,
    description: "",
    discount: 0,
    discountType: "percentage",
    inStock: true,
    images: [Dell_7240_1, Dell_7240_2, Dell_7240_3],
    details: {
      Series: "Latitude",
      Model: "E7240",
      CPU: "i5",
      Generation: "4th",
      Ram: "4",
      HDD: "128",
      Condition: "Used",
    },
  },
  {
    id: 42,
    name: "Dell 65w Normal Pin Adapter",
    price: 1500,
    categoryId: 15,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description:
      "Dell 65w normal pin adapter for dell inspiron 3521,3542,4050,5010 and latitude 6420,5430,5450 ,5440 5570,7270,7240 etc",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [Dell_charger_normal_pin],
    details: {},
  },
  {
    id: 43,
    name: "Dell Xps Pin Adapter",
    price: 1800,
    categoryId: 15,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description:
      "Dell 19.5v xps pin adapter for dell vostro 3468,inspiron 5559 and xps 9350 etc",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [Dell_xps_pin_charger],
    details: {},
  },
  {
    id: 44,
    name: "HP Normal Pin Adapter",
    price: 1500,
    categoryId: 15,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description:
      "HP 19.5V normal pin adapter for HP ELite book 8460, 8470, 840 g1, g2, Probook 640 g1, Folio 9470, 9480 etc",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [hp_normal_pin_charger],
    details: {},
  },
  {
    id: 45,
    name: "HP Blue Pin Adapter",
    price: 1800,
    categoryId: 15,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description:
      "HP 19.5V Blue pin adapter for hp Elitebook 840 G3, G4, G5, G6, G7, Probook 850 G4 G5 G6 G7 etc",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [hp_blue_pin_charger],
    details: {},
  },
  {
    id: 46,
    name: "Ram 4gb PC3, Ddr3",
    price: 2300,
    categoryId: 14,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description:
      "Ram 4gb PC3,Ddr3 Kingston, Samsung, Transcend, ADATA, Crucial etc",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [DDR3_DDR3L_RAM],
    details: {},
  },
  {
    id: 47,
    name: "Ram 4gb PC3L, Ddr3L",
    price: 2500,
    categoryId: 14,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description:
      "Ram 4gb PC3L, Ddr3L Kingston, Samsung, Transcend, ADATA, Crucial etc",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [DDR3_DDR3L_RAM],
    details: {},
  },
  {
    id: 48,
    name: "Ram 8gb PC3L, Ddr3L",
    price: 5200,
    categoryId: 14,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description:
      "Ram 8gb PC3L, Ddr3L Kingston, Samsung, Transcend, ADATA, Crucial etc",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [DDR3_DDR3L_RAM],
    details: {},
  },
  {
    id: 49,
    name: "Ram 4gb PC4, Ddr4",
    price: 3200,
    categoryId: 14,
    type: "accessory",
    active: true,
    isNewArrival: false,
    description:
      "Ram 4gb PC4, Ddr4 Kingston, Samsung, Transcend, ADATA, Crucial etc",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [DDR4_RAM],
    details: {},
  },
  {
    id: 50,
    name: "Ram 8gb PC4, Ddr4",
    price: 6000,
    categoryId: 14,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description:
      "Ram 8gb PC4, Ddr4 Kingston, Samsung, Transcend, ADATA, Crucial etc",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [DDR4_RAM],
    details: {},
  },
  {
    id: 51,
    name: "Ram 16gb PC4, Ddr4",
    price: 15_000,
    categoryId: 14,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description:
      "Ram 16gb PC4, Ddr4 Kingston, Samsung, Transcend, ADATA, Crucial etc",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [DDR4_RAM],
    details: {},
  },
  {
    id: 52,
    name: "128GB SSD KINGFAST",
    price: 3600,
    categoryId: 13,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description: "128GB SSD KINGFAST",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [kingfast_SSD],
    details: {},
  },
  {
    id: 53,
    name: "256GB SSD KINGFAST",
    price: 5800,
    categoryId: 13,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description: "256GB SSD KINGFAST",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [kingfast_SSD],
    details: {},
  },
  {
    id: 54,
    name: "512GB SSD KINGFAST",
    price: 10_500,
    categoryId: 13,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description: "512GB SSD KINGFAST",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [kingfast_SSD],
    details: {},
  },
  {
    id: 55,
    name: "500GB HARD DRIVE",
    price: 3500,
    categoryId: 11,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description: "500 GB Hard Drive Samsung,Toshiba, Kingston, WD, Seagate",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [Harddrive_500GB],
    details: {},
  },
  {
    id: 56,
    name: "1000GB HARD DRIVE",
    price: 5400,
    categoryId: 11,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description: "1000 GB Hard Drive Samsung,Toshiba, Kingston, WD, Seagate",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [Harddrive_1TB],
    details: {},
  },
  {
    id: 57,
    name: "Dell 3521 battery 6 cell",
    price: 5400,
    categoryId: 12,
    type: "accessory",
    active: false,
    isNewArrival: false,
    description: "Dell 3521 battery 6 cell Same Battery, 3542, 3537",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [],
    details: {},
  },
  {
    id: 58,
    name: "Dell 3468 battery 6 cell",
    price: 5400,
    categoryId: 12,
    type: "accessory",
    active: false,
    isNewArrival: false,
    description: "Dell 3568 battery 6 cell Same Battery, 3468, 5559, 5558",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [],
    details: {},
  },
  {
    id: 59,
    name: "Lenovo usb Adapter",
    price: 1800,
    categoryId: 15,
    type: "accessory",
    active: true,
    isNewArrival: true,
    description: "Lenovo usb adapter for t440, t540, t460, t470 etc",
    discount: 0,
    discountType: "",
    inStock: true,
    images: [Lenovo_USB_Charger],
    details: {},
  },
];
