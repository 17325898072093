export const categoryType = {
  All: "all",
  Laptop: "laptop",
  Accessories: "accessory",
};

export const discountTypes = {
  amount: "amount",
  percentage: "percentage",
};
