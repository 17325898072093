import {
  lenovo,
  hp,
  dell,
  acer,
  asus,
  msi,
  micro,
  razer,
  samsung,
  apple,
} from "../utils/images";

export const categoriesList = [
  {
    id: 1,
    name: "Apple",
    type: "laptop",
    active: true,
    image: apple,
    discount: 2000,
    discountType: "amount",
  },
  {
    id: 2,
    name: "HP",
    type: "laptop",
    active: true,
    image: hp,
    discount: 2000,
    discountType: "amount",
  },
  {
    id: 3,
    name: "Lenovo",
    type: "laptop",
    active: true,
    image: lenovo,
    discount: 2000,
    discountType: "amount",
  },
  {
    id: 4,
    name: "Dell",
    type: "laptop",
    active: true,
    image: dell,
    discount: 2000,
    discountType: "amount",
  },
  {
    id: 5,
    name: "Acer",
    type: "laptop",
    active: true,
    image: acer,
    discount: 2000,
    discountType: "amount",
  },
  {
    id: 6,
    name: "Asus",
    type: "laptop",
    active: true,
    image: asus,
    discount: 2000,
    discountType: "amount",
  },
  {
    id: 7,
    name: "MSI",
    type: "laptop",
    active: true,
    image: msi,
    discount: 2000,
    discountType: "amount",
  },
  {
    id: 8,
    name: "Microsoft Surface",
    type: "laptop",
    active: true,
    image: micro,
    discount: 2000,
    discountType: "amount",
  },
  {
    id: 9,
    name: "Razer",
    type: "laptop",
    active: true,
    image: razer,
    discount: 2000,
    discountType: "amount",
  },
  {
    id: 10,
    name: "Samsung",
    type: "laptop",
    active: true,
    image: samsung,
    discount: 2000,
    discountType: "amount",
  },
  {
    id: 11,
    name: "Hard Derive",
    type: "accessory",
    active: true,
    discount: 0,
    discountType: "",
  },
  {
    id: 12,
    name: "Battery",
    type: "accessory",
    active: true,
    discount: 0,
    discountType: "",
  },
  {
    id: 13,
    name: "SSD",
    type: "accessory",
    active: true,
    discount: 0,
    discountType: "",
  },
  {
    id: 14,
    name: "Ram",
    type: "accessory",
    active: true,
    discount: 0,
    discountType: "",
  },
  {
    id: 15,
    name: "Adapter",
    type: "accessory",
    active: true,
    discount: 0,
    discountType: "",
  },
];
